@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/spacing';
@import 'src/styles/variables';

.page {
  input > * {
    -webkit-transform: translateZ(0);
  }

  h1 {
    white-space: nowrap;
  }
}

.iconContainer {
  cursor: pointer;
  i {
    color: $ht-teal-500;
  }
  @include with-lg-grid {
    font-size: 20px;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  @include spacing(padding-right, 2);
  @include spacing(padding-left, 2);
  @include with-md-grid {
    @include spacing(padding-right, 4);
    @include spacing(padding-left, 4);
  }
  @include with-lg-grid {
    @include spacing(padding-right, 0);
    @include spacing(padding-left, 0);
  }
}

.header {
  position: relative;
  min-height: $ht-topnav-mobile-height;

  @include with-md-grid {
    min-height: $ht-topnav-tablet-height;
  }

  @include with-lg-grid {
    min-height: $ht-topnav-desktop-height;
  }

  .logoOuterWrapper {
    width: 100%;
    height: 100%;
    min-height: inherit;
    text-align: center;

    .logo {
      display: inline-block;
      font-size: 42px;

      @include with-md-grid {
        font-size: 50px;
      }

      @include with-lg-grid {
        font-size: 60px;
      }
    }
  }
}
