@import '~ht-styleguide/build/variables';

// Topnav Searchbar
$ht-searchbar-height-desktop: 48px;

$ht-tablet-width: 600px;
$ht-desktop-width: 840px;
$ht-large-desktop-width: 1200px;

// raw width values
$ht-desktop-width-raw: 769;
$ht-max-width-raw: 1920;
$ht-min-width-raw: 320;
$ht-tablet-width-raw: 415;

// colors
$ht-deep-navy-blue: #263746;
$ht-teal-green: #00c9bc;
$ht-success-green-500: #00cf7f;
$ht-blue: #4eaeeb;
$ht-dark-blue-tint-d1: #2992d4;
$ht-white: #fff;
$ht-red-orange: #f67d5b;

// Secondary
// for the naming, please use: http://chir.ag/projects/name-that-color/#F7F8FA
$ht-vibrant-red: #ff5959;

// Tints
$ht-deep-yellow-tint-l1: #ffedc7;
$ht-deep-yellow-tint-l2: #ffdc96;

$ht-deep-yellow-tint-l1: #ffedc7;
$ht-deep-yellow-tint-l2: #ffdc96;

$ht-cool-grey-tint-l1: #e7e7e7;
$ht-cool-grey-tint-l2: #b3b3b3;
$ht-cool-grey-tint-d2: #2e2e2e;

// TO REMOVE AS I PUT THESE IN STYLEGUIDE BRANCH
// Box Shadows
$ht-top-box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
$ht-box-shadow-all-sides: 0 0 5px 2px rgba(0, 0, 0, 0.1);
$ht-full-box-shadow-light: 0 0 2px 0 rgba(0, 0, 0, 0.1), 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
$ht-full-box-shadow-med: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 1px 16px 0 rgba(0, 0, 0, 0.1);
$ht-bottom-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

$theme-smart-home: (
  fontColor: linear-gradient(to bottom right, $ht-blue-700, $ht-blue-050),
  background: linear-gradient(to bottom right, $ht-blue-700, $ht-blue-050),
);

$ht-02-transition: 0.2s ease-in-out;

// z-index
$ht-z-index-pinned-container: 1; // needed to show above some components (reviews, etc)
$ht-z-index-sidebar: 10;
$ht-z-index-header: 7;
$ht-z-index-button-footer: $ht-z-index-header;
$ht-z-index-block-ui: 2000;
$ht-z-index-kustomer-root: 5000; // show under OneTrust banner
$ht-z-index-one-trust-banner: 6000; // show under toast notifications
$ht-z-index-slide-modal: 1990;
$ht-z-index-snackbar: $ht-z-index-slide-modal + 1;

// TopNav Header
$ht-topnav-mobile-height: 56px;
$ht-topnav-tablet-height: 64px;
$ht-topnav-desktop-height: 72px;

// For Notification/Promo Bar
$desktop-spacing-promotional-bar: 0;
$mobile-spacing-promotional-bar: 0;

// Button Footer
$ht-button-footer-mobile-height: 70px;
$ht-button-footer-tablet-height: 80px;
$ht-button-footer-desktop-height: 90px;

// Forms
$forms-input-border: #b3b3b3;
$forms-placeholder: #767676;
$forms-max-width: 600px;

// Breakpoints
// Max-width
$ht-max-width: 1920px;

// Shadows
$ht-bottom-only-box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.09);
$ht-box-shadow-header-bottom: 0 6px 8px rgba(38, 55, 70, 0.02); // one-off for headers

// z-index for FIXED/sticky LAYOUT ONLY components
$ht-z-index-header: 7;
$ht-z-index-secondary-header: $ht-z-index-header - 1;
$ht-z-index-chatCallDropdown: $ht-z-index-header + 2;

